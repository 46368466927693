import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import TitlePages from '../../сomponent/TitlePages/TitlePages';
import OfficeStructure from './components/office/structure';
import MiniPlatformItem from './components/PlatformHeader/miniItemPlatform';
import PlatformHeader from './components/PlatformHeader/platform';
import './components/style.css';
import API_URL from '../../config';

function ReportMain() {
  const [officeData, setOfficeData] = useState(null);
  const [reportDate, setReportDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedTime, setFormattedTime] = useState(''); // Новое состояние для времени
  const [statisticsPlatform, setStatisticsPlatform] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [availableDates, setAvailableDates] = useState([]);

  const platformNames = {
    cian: 'Циан',
    avito: 'Авито',
    domClick: 'ДомКлик',
    yandex: 'Я.Недвижимость',
  };

  useEffect(() => {
    // Получение доступных дат для отчета
    const fetchAvailableDates = async () => {
      try {
        const response = await axios.get(`${API_URL}/available-dates-report`);
        const dates = response.data.availableDates.map(date => parseISO(date));
        setAvailableDates(dates);
      } catch (error) {
        console.error("Error fetching available dates:", error);
      }
    };

    fetchAvailableDates();
  }, []);

  const fetchData = async (date) => {
    try {
      const response = await axios.post(`${API_URL}/report`, {
        date: format(date, 'yyyy-MM-dd'), // форматируем дату для запроса
      });

      if (response.data.status === "failed") {
        setErrorMessage("Файл за выбранную дату не найден");
        setOfficeData(null);
        setStatisticsPlatform(null);
      } else {
        setOfficeData(response.data); // предполагается, что сервер вернет данные по офису
        setStatisticsPlatform(response.data.statisticsPlatform); // предположим, что это объект из JSON
        setErrorMessage(''); // сбрасываем сообщение об ошибке
        // Форматируем дату и время для отображения
        const reportGeneratedAt = new Date(response.data.reportGeneratedAt);
        const formattedDate = format(reportGeneratedAt, 'dd.MM.yyyy');
        const formattedTime = format(reportGeneratedAt, 'HH:mm:ss'); // Форматируем время
        setFormattedDate(formattedDate); // предполагается, что сервер вернет дату отчета
        setFormattedTime(formattedTime); // Сохраняем время в состояние
      }
    } catch (error) {
      console.error("Error fetching office data:", error);
      setErrorMessage("Произошла ошибка при загрузке данных");
      setOfficeData(null);
      setStatisticsPlatform(null);
    }
  };

  useEffect(() => {
    fetchData(reportDate);
  }, [reportDate]);

  const handleDateChange = (date) => {
    setReportDate(date);
  };

  const isDateAvailable = (date) => {
    return availableDates.some(availableDate =>
      format(availableDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    );
  };
  const downloadExcelUrl = `${API_URL}/export-excel?date=${format(reportDate, 'yyyy-MM-dd')}`;

  return (
    <div className='space' style={{ width: "100%" }}>
      <TitlePages title="Информация о количестве выгруженных объектов" />
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <div className="defaultCard row pd-10" style={{ width: "220px", height: "fit" }}>
          <div className="x" style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            <div style={{ fontWeight: 500 }}>Сводка отчета</div>
            <div>{formattedDate}</div>
            <div>{formattedTime}</div> {/* Отображаем время */}
            <div>
              {statisticsPlatform ? (
                <>
                  <div style={{ paddingBottom: "10px", fontWeight: 600 }}>
                    <MiniPlatformItem
                      name="Всего"
                      count={statisticsPlatform.total || 0}
                      statCount={statisticsPlatform.compare.total || 0}
                    />
                  </div>

                  {Object.entries(platformNames).map(([key, name]) => (
                    <MiniPlatformItem
                      key={key}
                      name={name}
                      count={statisticsPlatform[key] || 0}
                      statCount={statisticsPlatform.compare[key] || 0}
                    />
                  ))}
                </>
              ) : (
                <div>Данные статистики отсутствуют в этой версии отчета</div>
              )}
            </div>
            <div className="buttonReport_download" style={{ width: "auto" }}>
            <a href={downloadExcelUrl} target="_blank" rel="noopener noreferrer">Скачать таблицу</a>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="defaultCard flexColumn pd-6">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px", justifyContent: "space-between", marginBottom: "10px", alignItems: "flex-end" }}>
              <div className="defaultCard row pd-10">
                <DatePicker
                  selected={reportDate}
                  onChange={handleDateChange}
                  dateFormat="dd.MM.yyyy"
                  className="custom-datepicker" // Добавляем кастомный класс
                  filterDate={isDateAvailable}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "6px", marginRight: "18px", alignItems: "flex-end", whiteSpace:"nowrap" }}>
                <div className="greenCard pd-6">Всего</div>
                <div className="blueCard pd-6">Лично</div>
                <div className="redCard pd-6">Потери</div>

                <div>
                  <div className="headGray pd-6">В рекламе</div>
                  <div style={{ display: "flex", marginTop: 4 }}>
                    <div style={{ marginRight: 4 }} className="headGray pd-6">Всего</div>
                    <div className="headGray pd-6">РГ (лично)</div>
                  </div>
                </div>

                {/* Ето отдельно */}

                <div>
                  <div>
                    <div className='ServiceCian pd-6'>
                      <img src="/src/img/platform-logo/cian.png"></img>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: 4, gap:4 }}>
                    <div className="headGray pd-6" style={{display:"flex", alignItem:"center"}}>Всего</div>
                    <div className="headGray pd-6">% от базы </div>
                    <div className="headGray pd-6">Отклонение</div>
                  </div>
                </div>

                {/* <div className="adsCard pd-6">В рекламе у РГ <strong>(лично)</strong></div>  */}

                <PlatformHeader platform={"avito"} />
                <PlatformHeader platform={"domClick"} />
                <PlatformHeader platform={"yandex"} />
              </div>
            </div>
            {/* Вывод по офисам */}
            {/* Офис -> РД - > РГ // Агентов РГ нет */}
            <OfficeStructure data={officeData} errorMessage={errorMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportMain;
