import React from 'react';
import './TitlePages.css';

const ItemEmployee = ({ title, param2, param3 }) => {
  return (
    <div className="infoReportContainer" style={{ marginBottom: "10px", padding:"10px" }}>
      <div>{title}</div>
      {/* //TODO: Стили для заголовка (жирный как в Figma) */}
    </div>
  );
}

export default ItemEmployee;
