import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import './App.css';

import Home from './pages/home/Home';
import Report from './pages/reports/ReportMain';


function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
          <div className="menu">
            <img className="logo" src='/src/img/logo.png' alt='Портал'></img>
            <div className="demo">
              Меню сервиса в разработке
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/report" element={<Report />} />
          </Routes>

          {/* <div className='rightInfo'>
          <InfoReport />
          <InfoReportComponent />
        </div> */}
        </div>
      </div>
    </Router>
  );
}

export default App;
