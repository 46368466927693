import React from 'react';
import './style.css';

function MiniPlatformItem({ name, count, statCount }) {
  // Определяем направление (up или down) на основе значения statCount
  const stat = statCount < 0 ? 'down' : 'up';

  // Словарь сопоставляет ключи с URL и alt текстом
  const stats = {
    up: {
      img: '/src/img/elements/up.png',
      alt: 'Повышение'
    },
    down: {
      img: '/src/img/elements/down.png',
      alt: 'Понижение'
    }
  };

  // Получаем данные для текущей платформы
  const { img, alt } = stats[stat];

  return (
    <div className='row' style={{ fontWeight: 400, justifyContent: "space-between", fontSize: "14px" }}>
      <div style={{ fontSize: "14px" }}>{name}</div>
      <div className='row' style={{ alignItems: "center" }}>
        <div style={{ color: "#565656" }}>{count}</div>
        <div className='row' style={{ alignItems: "center", marginLeft: "4px", color: "#929292" }}>
          <div>{statCount}</div>
          <img src={img} alt={alt} height={"10px"}></img>
        </div>
      </div>
    </div>
  );
}

export default MiniPlatformItem;
