import React from 'react';
import './style.css';

function PlatformHeader({ platform }) {
  // Словарь сопоставляет ключи с URL и alt текстом
  const platforms = {
    cian: {
      img: '/src/img/platform-logo/cian.png',
      alt: 'Циан'
    },
    avito: {
      img: '/src/img/platform-logo/avito.png',
      alt: 'Авито'
    },
    yandex: {
      img: '/src/img/platform-logo/yandex.png',
      alt: 'Яндекс'
    },
    domClick: {
      img: '/src/img/platform-logo/domClick.png',
      alt: 'ДомКлик'
    }
  };

  // Получаем данные для текущей платформы
  const { img, alt } = platforms[platform];

  return (
    <div>
    <div className='ServiceHeader pd-10'>
      <img src={img} alt={alt}></img>
    </div>
    </div>

  );
}

export default PlatformHeader;
