import React from 'react';
import HeadEmployee from '../headEmployee';
import ItemEmployee from '../ItemEmployee';

const OfficeStructure = ({ data, errorMessage }) => {
    if (errorMessage) {
        return <div>{errorMessage}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            {data.data.map((office, index) => (
                <div
                    key={index}
                    className="defaultCard pd-4"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        width: "auto",
                        backgroundColor: "#FAFCFF"
                    }}
                >
                    <div className="pd-4" style={{ fontWeight: "500", fontSize: "16px" }}>
                        {office.office.name}
                    </div>
                    {office.structure.map((head, headIndex) => (
                        <div
                            key={headIndex}
                            className="defaultCardGray pd-6"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                width: "auto"
                            }}
                        >
                            <HeadEmployee
                                name={`${head.lastname} ${head.name} ${head.fathername}`}
                                all={head.stat.all}
                                personal={head.stat.personal}
                                adsCRM={head.stat.adsCRM}
                                adsCRMPersonal={head.stat.adsCrmPersonal}
                                losses={head.stat.losses}
                                cian={head.platform.cian}
                                avito={head.platform.avito}
                                domClick={head.platform.domClick}
                                yandex={head.platform.yandex}
                            />
                            <div style={{ paddingLeft: "10px" }}>
                                {head.users.map((user, userIndex) => (
                                    <ItemEmployee
                                        key={userIndex}
                                        name={`${user.lastname} ${user.name} ${user.fathername}`}
                                        all={user.stat.all}
                                        personal={user.stat.personal}
                                        losses={user.stat.losses}
                                        adsCRM={user.stat.adsCRM}
                                        adsCRMPersonal={user.stat.adsCrmPersonal}
                                        cian={user.platform.cian}
                                        avito={user.platform.avito}
                                        domClick={user.platform.domClick}
                                        yandex={user.platform.yandex}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default OfficeStructure;
