import React from 'react';


const HeadEmployee = ({ name, all, personal, losses, cian, avito, domClick, yandex, adsCRM,adsCRMPersonal }) => {
    const percentage = Math.round((cian / all) * 100); // вычисляем процент
    return (
        <div className="row pd-8" style={{ backgroundColor: "#E9F7FF", border: "1px solid #B3E2FF", borderRadius: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                <div style={{ fontWeight: 500, fontSize: "14px" }}>{name}</div>
                <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                    <div className='CountService' style={{ width: "50px", textAlign: "center" }}>{all}</div>  
                    <div className='CountService' style={{ width: "60px", textAlign: "center" }}>{personal}</div>
                    <div className='CountService' style={{ width: "70px", textAlign: "center" }}>{losses}</div>
                    {/* <div className='CountService' style={{ width: "132px", textAlign: "center" }}>{adsCRM}</div>   */}

                    {/* В рекламе */}
                    <div className='CountService' style={{ width: "50px", textAlign: "center" }}>{adsCRM}</div>  
                    <div className='CountService' style={{ width: "90px", textAlign: "center" }}>{adsCRMPersonal}</div> 
                    

                    {/* Инфа по Циану */}
                    <div className='CountService' style={{ width: "57px", textAlign: "center" }}>{cian}</div>  
                    <div className='CountService' style={{ width: "87px", textAlign: "center" }}>{percentage}%</div>  
                    <div className='CountService' style={{ width: "106px", textAlign: "center" }}>N/A</div>   

                   {/* По площадкам */}
                    <div className='CountService' style={{ width: "84px", textAlign: "center" }}>{avito}</div> 
                    <div className='CountService' style={{ width: "122px", textAlign: "center" }}>{domClick}</div>
                    <div className='CountService' style={{ width: "140px", textAlign: "center" }}>{yandex}</div> 
                </div>

            </div>

        </div>
    );
}

export default HeadEmployee;
